<template>
  <div
    class="detail-content margin-content page-content-page"
    v-bind:class="{ UIHeaderNote: uiNote === 1 }"
    v-if="detailPage && checkShow"
  >
    <!--<div class="dc-header">
      <b-container class="d-flex align-items-center">
        <router-link
          :to="{
            name: 'library',
          }"
        >
          <div>コンテンツ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link
          :to="{
            name: 'DetailContentLibrary',
            params: { idContent: idContent },
          }"
        >
          <div>目次</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <div>{{ titleCata }}</div>
      </b-container>
    </div>-->
    <div class="h2 mb-3 font-title">{{ titleCata }}</div>
    <div
      class="dc-note d-flex align-items-center flex-column nav-bar__left justify-content-between desktop"
      v-scroll="handleScroll"
      style="
        height: calc(100vh - 120px);
        position: fixed;
        left: 0;
        z-index: 95;
        top: 120px;
      "
      v-if="uiNote === 0"
      v-click-outside="checkCloseModal"
    >
      <div class="dc-note-library d-flex align-items-center">
        <router-link
          name="mokuji_library_link"
          :to="{ name: 'library' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <!-- <b-icon icon="book" class="icon-note my-2" font-scale="2"></b-icon> -->
          <img
            src="@/assets/img/icon_side_library.png"
            alt=""
            class="icon-note my-2"
            style="height: 30px"
          />
          <div>コンテンツ</div>
        </router-link>
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="width: 100%"
      >
        <div
          class="dc-note-catalog d-flex flex-column justify-content-center align-items-center"
          style="
            border-bottom: 1px solid #e6e6e6;
            width: 100%;
            padding: 10px;
            border-top: 1px solid #e6e6e6;
          "
          name="mokuji_list_show"
          @click="selectedCategoryTitle(isCategoryTitle)"
        >
          <!-- <b-icon icon="list" class="icon-note my-2" font-scale="2"></b-icon> -->
          <img
            src="@/assets/img/icon_side_index.png"
            alt=""
            class="icon-note my-2"
            style="height: 30px"
          />
          <div>目次</div>
        </div>
        <div
          class="dc-note-note d-flex flex-column justify-content-center align-items-center"
          style="border-bottom: 1px solid #e6e6e6; width: 100%; padding: 10px"
          name="mokuji_note_show"
          @click="selectedListNote(isListNote)"
        >
          <img
            src="@/assets/img/icon_side_note.png"
            alt=""
            class="icon-note my-2"
            style="height: 30px"
          />
          <div>ノート/質問</div>
        </div>
      </div>
      <div
        class="dc-note-step d-flex flex-column justify-content-between align-items-center"
      >
        <div
          class="dc-note-step-item d-flex justify-content-center align-items-center"
        >
          <div>
            <div>
              <div class="mb-4" @click="PrevPage()">
                <!-- <router-link
                  name="mokuji_prev_link"
                  :to="{
                    name: 'DetailPageLibrary',
                    params: {
                      idPage: Number(idPagePrev),
                      idContent: idContent,
                    },
                  }"
                > -->
                <b-icon
                  icon="chevron-left"
                  class="icon-note my-2"
                  font-scale="2"
                ></b-icon>
                <!-- </router-link> -->
                <div>前へ</div>
              </div>
              <div @click="NextPage()">
                <!-- <router-link
                  name="mokuji_next_link"
                  :to="{
                    name: 'DetailPageLibrary',
                    params: {
                      idPage: Number(idPageNext),
                      idContent: idContent,
                    },
                  }"
                > -->
                <b-icon
                  icon="chevron-right"
                  class="icon-note my-2"
                  font-scale="2"
                ></b-icon>
                <!-- </router-link> -->
                <div>次へ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show"
      class="dc-main d-flex flex-column"
      style="position: relative"
    >
      <div
        class="sidebarContIndex height-text d-flex align-items-center flex-column nav-bar__left desktop row"
        v-scroll="handleScrollChapter"
        style="
          position: fixed;
          transition: all 0.5s;
          z-index: 98;
          overscroll-behavior-y: contain;
        "
        :style="[
          { left: isShow ? '90px!important' : '-100%!important' },
          isCategoryTitle === 1 && isSelectScreen !== 2
            ? { overflowY: 'auto' }
            : '',
          { top: topMdNote },
          { height: heightMdNote },
        ]"
        v-if="isCategoryTitle === 1 || isListNote === 1"
        @click="checkNot()"
      >
        <div v-if="isShow" class="w-100">
          <div v-if="isCategoryTitle === 1" class="w-100">
            <h2 class="sidebarTtl mb-0">もくじ</h2>
            <div class="w-100">
              <div class="contents-list">
                <div
                  class="contents-list-item position-relative"
                  v-for="(item, index) in listCata"
                  :key="index"
                >
                  <!-- <router-link
                    :to="{
                      name: 'DetailPageLibrary',
                      params: {
                        idPage: item.catalogue_id,
                        idContent: item.pivot.content_id,
                      },
                    }"
                    class="d-flex align-items-center"
                  >
                    <div style="width: 70px">
                      <div v-if="item.is_read" class="icon-check">
                        <b-icon icon="check"></b-icon>
                      </div>
                      <div v-else class="icon-check">
                        <img class="icon-no-read" :src="imgNoRead" alt="" />
                      </div>
                    </div>
                    <div class="textName">{{ item.title }}</div>
                  </router-link> -->
                  <div class="" style="">
                    <div style="width: 70px"></div>
                    <div>
                      <div
                        v-if="item.title"
                        class="customTitleChapter w-100"
                        v-bind:class="{ noBorderTop: index === 0 }"
                        style="margin-left: 70px; border-top: 1px solid #e6e6e6"
                      >
                        {{ item.title }}
                      </div>
                      <div class="" v-if="item.page">
                        <div
                          class="textName customTitlePage d-flex"
                          v-for="(ele, index) in item.page"
                          :key="index"
                          :class="{
                            checkedPage:
                              idPosition === item.position &&
                              idPageView === ele.id,
                          }"
                          @click="
                            changepage(ele, item.catalogue_id, item.position)
                          "
                        >
                          <div class="pageBorder">
                            <div
                              v-if="
                                ele.is_read ||
                                (idPosition === item.position &&
                                  idPageView === ele.id)
                              "
                              class="icon-check"
                            >
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div v-else class="icon-check">
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="padding-left: 45px">
                              {{ ele.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hoverTittlePage" v-else>
                        <div
                          class="textName customTitlePage d-flex"
                          :class="{
                            checkedPage: idPosition === item.position,
                          }"
                          @click="changepage(item, null, item.position)"
                        >
                          <div class="pageBorder">
                            <div
                              v-if="
                                item.is_read || idPosition === item.position
                              "
                              class="icon-check"
                            >
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div class="icon-check" v-else>
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="">
                              {{ item.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="
                      index === 0
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-above"
                  ></div>
                  <div
                    :style="
                      index === dataCata.length - 1
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-below"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="isListNote === 1" class="w-100">
            <h2 class="sidebarTtl mb-0 d-flex justify-content-between px-4">
              <div class="d-flex flex-nowrap">
                <span
                  @click="changeNoteOrChat(1)"
                  class="d-flex align-items-center active"
                  style="cursor: pointer"
                >
                  <a
                    data-obj-name="mokuji_note_link"
                    class="toList"
                    style="font-size: 14px; color: black"
                    >ノート</a
                  >
                </span>
                /
                <span
                  @click="changeNoteOrChat(2)"
                  class="d-flex align-items-center active"
                  style="cursor: pointer"
                >
                  <a
                    data-obj-name="mokuji_note_link"
                    class="toList"
                    style="font-size: 14px; color: black"
                    >質問</a
                  >
                </span>
              </div>
              <div>
                <!-- <a
                  data-obj-name="mokuji_add_note_link"
                  class="addNote btnNote mr-3"
                  data-type="normal"
                  style="font-size: 14px; color: black"
                  @click="showModalChat()"
                  >質問する</a
                > -->
                <a
                  data-obj-name="mokuji_add_note_link"
                  class="addNote btnNote"
                  data-type="normal"
                  style="font-size: 14px; color: black; cursor: pointer"
                  @click="setDataModal()"
                  >ノート</a
                >
              </div>
            </h2>
            <div class="w-100">
              <div class="tab">
                <button class="tablinks p-0" @click="changeNoteOrChat(1)">
                  <span
                    v-bind:class="{ isSelectedScreen: isSelectScreen === 1 }"
                    >ノート</span
                  >
                </button>
                <button class="tablinks p-0" @click="changeNoteOrChat(2)">
                  <span
                    v-bind:class="{ isSelectedScreen: isSelectScreen === 2 }"
                    >質問</span
                  >
                </button>
              </div>
              <div
                class="list-note"
                id="listNote"
                style="
                  overflow: auto;
                  height: calc(100vh - 100px);
                  max-height: 78vh;
                "
                v-if="isShowChat === 0"
                v-scroll="handleScrollNote"
              >
                <div v-if="isSelectScreen === 1">
                  <div
                    class="list-note-item my-2 position-relative"
                    v-for="(item, index) in listNoteData"
                    :key="index"
                  >
                    <div
                      class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
                    >
                      <div
                        class="title-item"
                        style="
                          word-break: break-all;
                          padding-right: 3%;
                          font-weight: bold;
                        "
                      >
                        {{
                          moment(new Date(item.created_at)).format("YYYY/MM/DD")
                        }}
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="list-note-button">
                          <img
                            src="@/assets/img/icon_pencil.png"
                            class="icon-note mr-3"
                            id="btn-show-note"
                            @click="showModalEdit(item.id)"
                            style="width: 20px; height: 20px; cursor: pointer"
                          />
                          <img
                            src="@/assets/img/icon_delete.png"
                            class="icon-note"
                            v-on:click="showConfirm(item.id)"
                            v-b-modal.modal-delete-note
                            style="width: 20px; height: 20px; cursor: pointer"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="list-note-item-content d-flex"
                      style="font-size: 16px; font-weight: bold"
                    >
                      {{ item.title_note }}
                    </div>
                    <div
                      class="list-note-item-content d-flex customContentNoteMobile"
                      style="word-break: break-all; font-size: 14px"
                    >
                      <div
                        v-html="
                          item.content_note
                            .toString()
                            .replaceAll('\n', '<br />')
                        "
                      ></div>
                    </div>
                    <div
                      class="list-note-item-pdf d-flex flex-column"
                      style="font-size: 14px; font-weight: bold"
                    >
                      <!-- <div>コンテンツ： {{ item.page_title }}</div> -->
                      <div>
                        PDF：{{
                          item.page_pdf === null ? 1 : item.page_pdf
                        }}枚目
                      </div>
                      <div>
                        動画：{{
                          item.seconds_watch_video !== null
                            ? item.seconds_watch_video
                            : 0
                        }}秒
                      </div>
                    </div>
                    <div
                      class="list-tag d-flex flex-wrap my-3 align-items-center"
                      v-if="item.tags.length > 0"
                    >
                      <div
                        class="position-relative d-flex align-items-center px-1 py-1"
                        v-for="(item, index) in item.tags"
                        :key="index"
                      >
                        <a class="customA"
                          ><span class="customSpan">{{
                            item.tag_name
                          }}</span></a
                        ><img
                          src="@/assets/img/bg_note_tag_active.png"
                          style="width: 18px; height: 20px"
                        />
                      </div>
                      &ensp;&ensp;
                    </div>
                  </div>
                </div>
                <div v-if="isSelectScreen === 2">
                  <div
                    class="list-note-item my-2 position-relative customTitle list-chat"
                    v-for="(item, index) in listChat.data"
                    :key="index"
                    @click="getDetailMessageByChatID(item)"
                  >
                    <div
                      class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
                    >
                      <div
                        class=""
                        style="word-break: break-all; padding-right: 3%"
                      >
                        作成日:{{
                          moment(new Date(item.created_at)).format("YYYY/MM/DD")
                        }}
                      </div>
                    </div>
                    <div
                      class="list-note-item-content"
                      style="font-size: 16px; font-weight: bold"
                    >
                      <!-- <img
                        :src="env + item.image"
                        style="width: 18px; height: 20px"
                      /> -->
                      <div class="row mx-0">
                        <div class="col-2 px-0">
                          <img
                            v-if="item.image"
                            :src="env + item.image"
                            width="80px"
                            height="80px"
                          />
                          <img
                            v-else-if="item.image === null || item.image === ''"
                            src="@/assets/img/no-image.jpg"
                            width="70px"
                            height="70px"
                          />
                        </div>
                        <div class="col-8 px-0">
                          <div class="mt-3">質問「{{ item.message }}」</div>
                        </div>
                        <!--<div class="col-4 px-0">
                          <div
                            class="mt-3"
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                          </div>
                        </div>-->
                      </div>
                    </div>
                    <!-- <div
                      class="list-note-item-content d-flex"
                      style="font-size: 16px; font-weight: bold"
                    >
                      【 {{ item.title }} 】
                    </div> -->
                    <div
                      class="list-note-item-content d-flex customContentNoteMobile"
                      style="word-break: break-all; font-size: 14px"
                    >
                      <!-- <div
                        v-html="item.content_note.toString().replaceAll('\n', '<br />')"
                    ></div> -->
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center position-relative"
                    v-if="listChat.total"
                  >
                    <CPagination
                      v-if="listChat.total"
                      :activePage.sync="page"
                      :pages="listChat.last_page"
                      align="center"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isShowChat === 1">
                <div
                  style="height: 30px"
                  class="d-flex flex-nowrap align-items-center px-3"
                >
                  <span
                    >{{ userProfile.last_name
                    }}{{ userProfile.first_name }}</span
                  >
                </div>
                <div
                  v-scroll="handleScrollMessage"
                  class=""
                  style="
                    background-color: #668ad8b8;
                    overflow: auto;
                    position: relative;
                  "
                  id="bodyChat"
                  :style="{ height: heightMessage }"
                >
                  <div
                    class=""
                    style="padding: 10px 12px"
                    v-for="(itemMess, index) in listMessageByChatID"
                    v-bind:key="index"
                  >
                    <!-- UI Message ADMIN -->
                    <div
                      v-if="itemMess.user_type != 4 && itemMess.user_type != 5"
                      class="d-flex flex-nowrap align-items-center"
                    >
                      <div class="customChatAdmin mt-3">
                        <div class="d-flex flex-column p-3">
                          <div
                            style="word-wrap: break-word"
                            v-if="itemMess.message"
                          >
                            <div
                              v-html="replaceMessage(itemMess.message)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End UI Message ADMIN -->

                    <!-- UI Message USER -->
                    <div
                      class="d-flex adc flex-nowrap flex-row-reverse align-items-center"
                      v-if="itemMess.user_type == 4 || itemMess.user_type == 5"
                    >
                      <div class="customChatUser mt-3">
                        <div class="d-flex flex-column p-3">
                          <div
                            style="word-wrap: break-word"
                            v-if="itemMess.message"
                          >
                            <div
                              v-html="replaceMessage(itemMess.message)"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pr-3 mt-3 del-mess"
                        v-if="checkDelete != itemMess.id"
                        @click="deletMessage(itemMess.id)"
                      >
                        <i class="fas fa-times" style="color: white"></i>
                      </div>
                    </div>
                    <!-- End UI Message USER -->
                  </div>
                </div>
                <div
                  class="custom-control CustomInputChat w-100 pl-0"
                  style="background-color: white"
                >
                  <div
                    class="p-1 d-flex flex-nowrap justify-content-center align-items-center"
                  >
                    <textarea
                      class="hoverMouse pl-0 px-4"
                      v-model="titleSendMessage"
                      placeholder="返信内容を記載"
                      style="
                        border: 1px solid #fff;
                        width: 80%;
                        background-color: #d8dbe0;
                        border-radius: 30px;
                        padding-bottom: 3px;
                        resize: none;
                      "
                      rows="3"
                      no-resize
                      @keydown.enter.exact.prevent="titleSendMessage += '\n'"
                      @keydown.enter.shift.exact.prevent="ClickSendMessage()"
                      @keyup="checkLimitLength"
                      :class="isLoading ? 'disable-div' : ''"
                    ></textarea>
                    <!-- <b-form-textarea
                      id="textarea-no-resize"
                      placeholder="Fixed height textarea"
                      rows="2"
                      no-resize
                    ></b-form-textarea> -->
                    <div
                      style="width: 20%"
                      class="d-flex flex-column justify-content-center align-items-center"
                      :class="isLoading ? 'disable-div' : ''"
                    >
                      <img
                        v-on:click="ClickSendMessage()"
                        src="@/assets/img/send.png"
                        style="width: 18px; height: 20px; cursor: pointer"
                      />
                      <div>返信</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dc-main-list ml-auto"
        style="width: 100%"
        v-bind:class="{ UINote: uiNote === 1 }"
      >
        <div class="accordion accordion-page" role="tablist">
          <!-- <b-card
            no-body
            class="mb-1 border-0 p-0 bg-transparent"
            v-for="(item, index) in catalogueById"
            :key="index"
          > -->
          <!-- <b-card-header header-tag="header" class="border-0 p-0" role="tab">
            <b-button
              block
              @click="accordionPageContent(detailPage.id)"
              variant="info"
              >{{ detailPage.page_title }}</b-button
            >
          </b-card-header> -->
          <b-collapse
            :id="'accordionId_' + detailPage.id"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body
              class="px-0"
              :style="{
                lineHeight: detailPage.line_height
                  ? detailPage.line_height + 'px'
                  : 'unset',
              }"
            >
              <div
                class="w-100"
                v-for="(itemBody, indexBody) in page_Content.blocks"
                :key="indexBody"
                :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
              >
                <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'paragraph'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :style="{
                    lineHeight:
                      itemBody.data.lineHeight &&
                      itemBody.data.lineHeight !== null
                        ? itemBody.data.lineHeight
                        : '',
                  }"
                >
                  <div
                    v-if="itemBody.data.text"
                    v-html="itemBody.data.text"
                  ></div>
                  <div
                    v-else
                    v-html="itemBody.data.text"
                    style="height: 1.6em"
                  ></div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'tableOfContents'"
                >
                  <h3>目次</h3>
                  <ul class="list-unstyled pl-4 listTableOfContent">
                    <li
                      v-for="(itemTOC, index) in itemBody.data.items"
                      :key="index"
                      class="pl-3"
                    >
                      <a
                        style="cursor: pointer"
                        v-scroll-to="{ el: '#' + itemTOC.href, offset: -20 }"
                        v-html="itemTOC.text"
                      ></a>
                    </li>
                  </ul>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'header'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <h1
                    v-if="itemBody.data.level === 1"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h1>
                  <h2
                    v-if="itemBody.data.level === 2"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h2>
                  <h3
                    v-if="itemBody.data.level === 3"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h3>
                  <h4
                    v-if="itemBody.data.level === 4"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h4>
                  <h5
                    v-if="itemBody.data.level === 5"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h5>
                  <h6
                    v-if="itemBody.data.level === 6"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h6>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'list'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <ul class="w-100" style="list-style-type: decimal">
                    <li
                      v-for="(itemList, indexList) in itemBody.data.items"
                      :key="indexList"
                      class="mb-2"
                      v-html="itemList"
                    ></li>
                  </ul>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                    :class="
                      itemBody.data.status === 'open'
                        ? 'toggle-open'
                        : 'toggle-close'
                    "
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <b-icon
                        :icon="
                          itemBody.data.status === 'open' ? 'x-lg' : 'plus-lg'
                        "
                      ></b-icon>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div>
                <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'code'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.code"></div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100 my-3 editor-raw"
                  v-if="itemBody.type === 'raw'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : ''
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.html"></div>
                  <!-- <code>{{ itemBody.data.html }}</code> -->
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'table'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          v-for="(itemTblTh, indexTblTh) in itemBody.data
                            .content[0]"
                          :key="indexTblTh"
                          class="border-bottom-0"
                        >
                          <span class="w-100" v-html="itemTblTh"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(itemTblTr, indexTblTr) in itemBody.data
                          .contentTbody"
                        :key="indexTblTr"
                      >
                        <td
                          v-for="(itemTblTd, indexTblTd) in itemTblTr"
                          :key="indexTblTd"
                        >
                          <span class="w-100" v-html="itemTblTd"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'checklist'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="custom-control custom-checkbox"
                    v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                    :key="indexCheckbox"
                  >
                    <input
                      :id="'checkbox-' + itemBody.id + indexCheckbox"
                      type="checkbox"
                      :name="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-input"
                      :checked="itemCheckbox.checked"
                    />
                    <label
                      :for="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-label ml-1"
                    >
                      <span v-html="itemCheckbox.text"></span>
                    </label>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'warning'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 bg-warning p-3 text-white">
                    <div class="w-100 font-weight-bold">
                      {{ itemBody.data.title }}
                    </div>
                    <hr />
                    <div class="w-100">
                      {{ itemBody.data.message }}
                    </div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'quote'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <blockquote
                    class="otro-blockquote"
                    :class="
                      itemBody.data.alignment === 'left'
                        ? 'text-left'
                        : 'text-right'
                    "
                  >
                    <span>
                      {{ itemBody.data.caption }}
                    </span>
                    <span v-html="itemBody.data.text"></span>
                  </blockquote>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'headerStyle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="titleStyle" :class="itemBody.data.class">
                    <h1 class="dataInput">
                      {{ itemBody.data.text }}
                    </h1>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'boxStylle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="boxStyle" :class="itemBody.data.class">
                    <span
                      class="box-title px-2 border-0"
                      v-if="itemBody.data.title"
                      >{{ itemBody.data.title }}</span
                    >
                    <div
                      class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                      v-html="itemBody.data.text"
                    ></div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'delimiter'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 text-center ce-delimiter"></div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'image'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <a
                    v-if="replaceText(itemBody.data.linkImage)"
                    :href="itemBody.data.linkImage"
                  >
                    <img :src="itemBody.data.file.url" alt="" width="auto" />
                  </a>
                  <img
                    v-else
                    :src="itemBody.data.file.url"
                    alt=""
                    width="auto"
                  />
                  <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <!-- <ViewPdf
                    v-bind:pageNumber="lastPagePDF"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:idLast="idLastPDF"
                    v-bind:src="itemBody.data.file.url"
                    v-bind:idComponent="itemBody.id"
                  /> -->
                  <b-button
                    variant="primary"
                    class="btn-preview-fixed mr-0"
                    name="btn-view-PDF"
                    v-on:click.prevent="viewFilePDF(itemBody.data.file)"
                  >
                    {{ itemBody.data.title }}
                  </b-button>
                </div>
                <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'youtube'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <YoutubePlayer
                    v-bind:idElemYoutube="itemBody.id"
                    v-bind:idLastYoutube="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.source"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                    v-bind:dataVideo="itemBody.data"
                  />
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  :class="
                    itemBody.idChild
                      ? checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'vimeo'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <VimeoPlayer
                    v-bind:idElemVimeo="itemBody.id"
                    v-bind:idLastVimeo="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.embed"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                    v-bind:dataVideo="itemBody.data"
                  />
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'anyButton'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                  <a
                    v-if="itemBody.data.type === null"
                    class="anyButton"
                    :class="itemBody.data.style"
                    @click="downloadFile($event, itemBody.data)"
                  >
                    {{ itemBody.data.text }}
                  </a>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'roundButton' ||
                        itemBody.data.style === 'socialGlossySmooth' ||
                        itemBody.data.style === 'socialSquare')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <a
                      v-if="itemBody.data.twitter !== null"
                      :href="itemBody.data.twitter"
                      class="btn-social-circle btn-social-circle--twitter"
                      ><i class="fab fa-twitter"></i>
                    </a>
                    <a
                      v-if="itemBody.data.facebook !== null"
                      :href="itemBody.data.facebook"
                      class="btn-social-circle btn-social-circle--facebook"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      v-if="itemBody.data.pocket !== null"
                      :href="itemBody.data.pocket"
                      class="btn-social-circle btn-social-circle--pocket"
                      ><i class="fab fa-get-pocket"></i>
                    </a>
                    <a
                      v-if="itemBody.data.feedly !== null"
                      :href="itemBody.data.feedly"
                      class="btn-social-circle btn-social-circle--feedly"
                      ><i class="fas fa-rss"></i
                    ></a>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialGiza' ||
                        itemBody.data.style === 'socialSmartPhone')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza'
                          : 'btn-social-phone'
                      "
                    >
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-twitter'
                            : 'btn-social-phone-twitter ml-0 mr-3'
                        "
                        v-if="itemBody.data.twitter !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-twitter fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="3535"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-facebook'
                            : 'btn-social-phone-facebook ml-0 mr-3'
                        "
                        v-if="itemBody.data.facebook !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="5467"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-pocket'
                            : 'btn-social-phone-pocket ml-0 mr-3'
                        "
                        v-if="itemBody.data.pocket !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-get-pocket fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-feedly'
                            : 'btn-social-phone-feedly ml-0 mr-3'
                        "
                        v-if="itemBody.data.feedly !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fas fa-rss fa-stack-1x"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialFlat' ||
                        itemBody.data.style === 'socialIsometric')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : checkIndex(itemBody)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                          "
                        >
                          <i class="fab fa-twitter"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                          "
                        >
                          <i class="fab fa-facebook"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                          "
                        >
                          <i class="fab fa-get-pocket"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                          "
                        >
                          <i class="fas fa-rss"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100 my-3"
                  v-if="itemBody.type === 'attachesFile'"
                >
                  <div class="w-100 d-flex box-file-upload">
                    <div
                      class="iconFile"
                      :data-extension="itemBody.data.file.extension"
                      :style="{ color: itemBody.data.file.color }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="40"
                      >
                        <path
                          d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                        />
                      </svg>
                    </div>
                    <div class="fileName">
                      <div class="w-100">{{ itemBody.data.file.name }}</div>
                      <div
                        class="text-muted w-100"
                        v-html="convertSize(itemBody.data.file.size)"
                      ></div>
                    </div>
                    <div class="iconDowload ml-auto">
                      <a
                        class="iconDowload"
                        v-bind:download="itemBody.data.file.url"
                        rel="nofollow noindex noreferrer"
                        @click="downloadFileUpload($event, itemBody.data.file)"
                      >
                        <i class="fas fa-cloud-download-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!-- </b-card> -->
        </div>
      </div>
      <DetailPageByUINote v-if="isShowButtonChatOrNote === true" />
      <BottomMenuNote v-if="uiNote === 1" />
    </div>
    <!-- Modal List catalog -->
    <b-modal id="list-catalog" size="xl" title="目次" hide-footer>
      <CRow
        ><CCol sm="12">
          <div class="contents-list">
            <div
              class="contents-list-item"
              v-for="(item, index) in listCata"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'DetailPageLibrary',
                  params: {
                    idPage: item.catalogue_id,
                    idContent: idURL,
                  },
                }"
                class="d-flex align-items-center"
              >
                <div class="icon-check">
                  <b-icon icon="check"></b-icon>
                </div>
                <div class="textName">{{ item.title }}</div>
              </router-link>
            </div>
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal List catalog -->
    <!-- Modal List Note -->
    <b-modal
      id="list-note"
      size="xl"
      title="コンテンツエリア
"
      hide-footer
    >
      <CRow
        ><CCol sm="12">
          <div class="list-note">
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                v-b-modal.modal-create-note
                @click="callStartGetCurrentEmbed"
                ><b-icon icon="plus" font-scale="2"></b-icon
                >メモを作成</b-button
              >
            </div>
            <div
              class="list-note-item my-2 position-relative"
              v-for="(item, index) in listNote"
              :key="index"
            >
              <div
                class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
              >
                <div
                  class="title-item"
                  style="word-break: break-all; padding-right: 3%"
                >
                  {{ item.title_note }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="time-item mx-2">
                    {{
                      new Date(item.created_at).toLocaleString("ja-JP", {
                        timeZone: "Asia/Tokyo",
                      })
                    }}
                  </div>
                  <div class="list-note-button">
                    <b-button
                      variant="warning"
                      class="mx-2"
                      @click="showModalEdit(item.id)"
                      ><b-icon
                        icon="pencil-square"
                        class="icon-note my-2"
                      ></b-icon>
                    </b-button>
                    <b-button
                      variant="danger"
                      class="mx-2"
                      v-on:click="showConfirm(item.id)"
                      v-b-modal.modal-delete-note
                      ><b-icon icon="trash" class="icon-note my-2"></b-icon
                    ></b-button>
                  </div>
                </div>
              </div>
              <div
                class="list-note-item-content d-flex"
                style="word-break: break-all"
              >
                {{ item.content_note }}
              </div>
              <div class="list-note-item-pdf d-flex flex-column">
                <div>コンテンツ： {{ item.page_title }}</div>
                <div>
                  PDF：{{ item.page_pdf === null ? 1 : item.page_pdf }}枚目
                </div>
                <div>
                  動画：{{
                    item.seconds_watch_video !== null
                      ? item.seconds_watch_video
                      : 0
                  }}秒
                </div>
              </div>
              <div
                class="list-tag d-flex flex-wrap my-3"
                v-if="item.tags.length > 0"
              >
                <div
                  class="item-tag mx-2 position-relative"
                  v-for="(item, index) in item.tags"
                  :key="index"
                >
                  {{ item.tag_name }}
                </div>
              </div>
            </div>
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal List Note -->
    <!-- Modal Create Note -->
    <b-modal
      id="modal-create-note"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
      body-class="p-0"
    >
      <template #modal-header="{}">
        <div class="w-100 p-0" @click="checkNot()">
          <div class="w-100 d-flex">
            <h5 class="w-100" style="text-align: center">ノート</h5>
            <b-button
              size="sm"
              style="background-color: white; border-color: white"
              class="close"
              @click="closeModal()"
            >
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </div>
      </template>
      <CRow @click="checkNot()"
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          /></CCol
        ><CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ page_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ seconds_watch_video }} 秒</div>
        </CCol>
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="" @click="checkNot()">
          <b-button variant="secondary" @click="closeModal()" class=""
            >キャンセル</b-button
          >
          <b-button
            v-on:click.prevent="CreateNewNote()"
            :disabled="isLoading"
            variant="info"
            class=""
            >完了
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal Create Note -->
    <!-- Modal Edit Note -->
    <b-modal
      id="modal-edit-note"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
      body-class="p-0"
    >
      <template #modal-header="{}">
        <div class="w-100 p-0" @click="checkNot()">
          <div class="w-100 d-flex">
            <h5 class="w-100" style="text-align: center">ノート</h5>
            <b-button
              size="sm"
              style="background-color: white; border-color: white"
              class="close"
              @click="closeModalEdit()"
            >
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </div>
      </template>
      <CRow @click="checkNot()"
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="detail_title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="detail_content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          />
        </CCol>
        <CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ detail_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ detail_video }} 秒</div></CCol
        >
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="" @click="checkNot()">
          <b-button variant="secondary" @click="closeModalEdit()" class=""
            >戻る</b-button
          >
          <b-button
            v-on:click.prevent="editNote()"
            :disabled="isLoading"
            variant="info"
            class=""
            >完了
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal Edit Note -->
    <!-- Modal Delete Note -->
    <b-modal id="modal-delete-note" hide-header hide-footer body-class="p-0">
      <div @click="checkNot()" class="p-3">
        <div class="d-block text-center px-4" style="padding: 10px">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div
          class="d-flex justify-content-center px-4"
          style="padding-bottom: 10px"
        >
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeNote(dataModal)"
            >削除する</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('modal-delete-note')"
          >
            キャンセル
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Modal Delete Note -->
    <!-- <div class="sidebar-menu mobile" v-if="uiNote !== 1">
      <div class="sidebarList">
        <b-row>
          <b-col>
            <div
              v-bind:class="{
                disabledButton: this.listCata.length > idPageNext,
              }"
            >
              <router-link
                :to="{
                  name: 'DetailPageLibrary',
                  params: {
                    idPage: Number(idPagePrev),
                    idContent: idContent,
                  },
                }"
              >
                <b-icon
                  icon="chevron-left"
                  font-scale="3"
                  style="color: black"
                ></b-icon>
              </router-link>
              <div
                v-bind:class="{
                  disabledDiv: this.listCata.length > idPageNext,
                }"
              >
                前へ
              </div>
            </div>
          </b-col>
          <b-col>
            <router-link :to="{ name: 'library' }">
              <img
                src="@/assets/img/icon_side_library.png"
                alt=""
                style="height: 30px"
              />
              <div style="color: black">コンテンツ</div>
            </router-link>
          </b-col>
          <b-col>
            <div v-b-modal.list-catalog>
              <img
                src="@/assets/img/icon_side_index.png"
                alt=""
                style="height: 30px"
              />
              <div>目次</div>
            </div>
          </b-col>
          <b-col>
            <div v-b-modal.list-note>
              <img
                src="@/assets/img/icon_side_note.png"
                alt=""
                style="height: 30px"
              />
              <div>ノート記録</div>
            </div>
          </b-col>
          <b-col>
            <div>
              <div
                v-bind:class="{
                  disabledButton: this.listCata.length < idPageNext,
                }"
                class="mb-4"
              >
                <router-link
                  :to="{
                    name: 'DetailPageLibrary',
                    params: {
                      idPage: Number(idPageNext),
                      idContent: idContent,
                    },
                  }"
                >
                  <b-icon
                    icon="chevron-right"
                    font-scale="3"
                    style="color: black"
                  ></b-icon>
                </router-link>
                <div
                  v-bind:class="{
                    disabledButton: this.listCata.length < idPageNext,
                  }"
                >
                  次へ
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div> -->
    <!-- Modal create chat-->
    <b-modal id="modal-create-chat" modal-class="" style="min-width: 720px">
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">質問</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalChat()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow>
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="valueChat"
            placeholder="メッセージ"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="1000"
          />
        </CCol>
      </CRow>
      <template #modal-footer="{}">
        <div class="">
          <b-button variant="secondary" @click="closeModalChat()" class=""
            >キャンセル</b-button
          >
          <b-button
            v-on:click.prevent="CreateNewChat()"
            :disabled="isLoading"
            variant="info"
            class=""
            >送信
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-alert-chat"
      modal-class=""
      style="min-width: 720px"
      hide-header
      hide-footer
    >
      <CRow @click="checkNot()">
        <CCol sm="12" style="padding: 30px">
          <span>質問送信前に以下ご確認をお願いします。<br /></span>
          <span>・ノート内容は制作者側では見れません。<br /></span>
          <span>「質問」のみしか見れませんのでご留意ください。<br /></span>
          <span
            >※個別の回答ではなく、別のコンテンツやメルマガなどで回答されることがありますので<br
          /></span>
          <span>あらかじめご了承くださいませ。</span><br />
          <div class="w-100 mt-4 d-flex justify-content-center">
            <b-button
              v-on:click.prevent="applyAgree()"
              :disabled="isLoading"
              variant="info"
              class=""
              >内容について同意し送信する
            </b-button>
          </div>
          <div class="remember-form mt-3">
            <input
              v-model="remember_me"
              type="checkbox"
              name="remember"
              id="remember"
              @change="readPopup"
            />
            <label class="ml-1" for="remember"
              >今後はこのアナウンスを表示しない</label
            >
          </div>
        </CCol>
      </CRow>
    </b-modal>
    <b-modal
      id="modal-detail-pdf"
      hide-footer
      modal-class="modal-fullscreen"
      v-model="modalShow"
    >
      <ViewPdf
        v-bind:src="filePDFView && filePDFView.url"
        v-bind:idComponent="filePDFView && filePDFView.idComponent"
        v-bind:dataInfo="filePDFView"
      />
    </b-modal>
  </div>
</template>

<script src="https://player.vimeo.com/api/player.js"></script>
<script>
import { eventBus } from "../../main";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import ViewPdf from "../../components/viewPdf/index.vue";
// import ViewPdf from "../../components/viewPdf/pdfView.vue";
import moment from "moment";
import { Constants } from "../../utils/constants";
import imgNoRead from "../../assets/img/icon_text.png";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import BottomMenuNote from "../default/BottomMenuNote.vue";
import DetailPageByUINote from "../default/DetailPageByUINote.vue";
import vClickOutside from "v-click-outside";
import { getIdFromUrl } from "vue-youtube";
import Player from "@vimeo/player";
// import { BIconNodePlusFill } from 'bootstrap-vue';
// import $ from "jquery";
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
export default {
  name: "DetailPageLibrary",
  components: {
    YoutubePlayer,
    VimeoPlayer,
    ViewPdf,
    BottomMenuNote,
    DetailPageByUINote,
    Player,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      idContent: null,
      dataCata: null,
      titleCata: "",
      idCata: null,
      title: "",
      content: "",
      isLoading: false,
      tag: "",
      seconds_watch_video: 0,
      page_pdf: 0,
      openNote: false,
      dataModal: "",
      tag_id: "",
      tags: [],
      listTagOptions: [],
      selectedTag: [],
      acceptPDF: false,
      acceptVideo: false,
      detailNote: {},
      detail_title: "",
      detail_content: "",
      detail_video: "",
      detail_pdf: 0,
      isAdded: 0,
      listCata: [],
      idPageNext: 0,
      idPagePrev: 0,
      detail_page_title: "",
      currentTimeYoutu: 0,
      currentPagePdf: 1,
      isListNote: 0,
      isCategoryTitle: 0,
      isSelect: null,
      idURL: this.$route.params.idContent,
      isTagChecked: [],
      ind: null,
      isShow: false,
      valueChat: null,
      idPageView: parseInt(this.$route.params.idPage),
      idLastEmbed: null,
      timeWatchingEmbed: 0,
      idLastPDF: null,
      lastPagePDF: null,
      loadDataNote: true,
      isSelectScreen: 1,
      page: 1,
      env: Constants.URL_BE + "/storage/",
      isShowChat: 0,
      titleSendMessage: "",
      idChat: null,
      imgNoRead,
      remember_me: false,
      isShowPopupRead: false,
      detailPage: {},
      page_Content: [],
      listPage: [],
      idChapterView: parseInt(this.$route.query.idChapter),
      uiNote: 0,
      listNote: null,
      noteByPage: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      idPosition: this.$route.query.position,
      idPage: null,
      idChapter: null,
      position: null,
      checkDelete: null,
      previousTitleSendMessage: null,
      notCheck: false,
      listNoteData: null,
      checkShow: null,
      statusYT: {
        playing: 1,
        paused: 2,
        ended: 0,
      },
      time_start_Yt: null,
      time_start_Vm: null,
      statusClick: {
        start: 0,
        paused: 1,
        end: 2,
      },
      dynamicTime: {},
      dynamicTimeVimeo: {},
      filePDFView: null,
      modalShow: false,
      dataReturn: null,
      topMdNote: 0,
      heightMdNote: 0,
      heightMessage: "calc(100vh - (340px))",
      show: true,
    };
  },
  async created() {
    this.$store.commit("set", ["modalLoadingPage", true]);
    const { params } = this.$route;
    this.idContent = params.idContent;
    this.idCata = params.idPage;
    await this.getContentLibraryById({
      id: params.idContent,
      shop_id: this.shop_id,
    });
    await this.getDetailPage();
    setTimeout(() => {
      var listRaw = document.getElementsByClassName("editor-raw");
      if (listRaw && listRaw.length > 0) {
        listRaw.forEach((raw) => {
          var listIframe = raw.getElementsByTagName("iframe");
          if (listIframe && listIframe.length > 0) {
            listIframe.forEach((ifMedia) => {
              if (ifMedia.src.includes("youtube.com")) {
                var videoId = getIdFromUrl(ifMedia.src);
                var id = raw.id + "_" + videoId + "_iframe_yt";
                ifMedia.outerHTML = `<div id='` + id + `'></div>`;
                this.settingPlayerYoutube(id, videoId);
                let name = "time_yt_" + videoId;
                this.dynamicTime[name] = null;
              } else if (ifMedia.src.includes("vimeo.com")) {
                var player = new Player(ifMedia);
                const selfVimeo = this;
                var vimeoId = selfVimeo.getVideoIDVimeo(ifMedia.src);
                let name = "time_vimeo_" + vimeoId;
                this.dynamicTimeVimeo[name] = null;
                player.on("play", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["start"],
                    player,
                    vimeoId
                  );
                });
                player.on("pause", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["paused"],
                    player,
                    vimeoId
                  );
                });
                player.on("ended", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["end"],
                    player,
                    vimeoId
                  );
                });
              }
            });
          }
        });
      }
    }, 600);
    var tag = document.createElement("script");
    tag.id = "iframe-youtube";
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    this.$store.commit("set", ["modalLoadingPage", false]);

    let req = {
      shop_id: this.shop_id,
    };
    await this.checkSentence(req);
  },
  watch: {
    async detailPage() {
      if (this.detailPage) {
        if (this.detailPage.url_redirect) {
          window.href = this.detailPage.url_redirect;
        }
        if (typeof this.detailPage.page_content === "string") {
          this.detailPage.page_content = JSON.parse(
            this.detailPage.page_content
          );
        }
        const { params } = this.$route;
        // this.getListNote(params.idContent);
        const dataRequest = {
          shop_id: parseInt(this.shop_id),
          content_id: parseInt(this.$route.params.idContent),
          catalogue_id: parseInt(this.idChapterView),
          page_id: parseInt(this.idPageView),
        };
        await this.getCatalogue({
          id: params.idPage,
          content_id: this.$route.params.idContent,
        });
        await this.updateViewCatalogue(dataRequest);
        await this.getListNoteByPages();
        // await this.getListChats(1);
        const dataReq = {
          page_id: this.$route.params.idPage,
          content_id: this.$route.params.idContent,
          shop_id: this.shop_id,
        };
        if (this.$route.query.idChapter) {
          dataReq["catalogue_id"] = this.$route.query.idChapter;
        }
        await this.getChatByPageId(dataReq);
        const formData = {
          shop_id: this.shop_id,
          user_type: localStorage.getItem(Constants.USER_TYPE_USER),
        };
        await this.getProfile(formData);
        const shop_id = this.shop_id;
        this.getListTag(shop_id);
        // const interval = setInterval(function () {
        //   if (document.getElementById("disable-right-click")) {
        //     document
        //       .getElementById("disable-right-click")
        //       .addEventListener("contextmenu", (event) =>
        //         event.preventDefault()
        //       );
        //     clearInterval(interval);
        //   }
        // }, 50);
        // document.addEventListener("contextmenu", (event) =>
        //   event.preventDefault()
        // );
      }
    },
    contentLibraryById() {
      this.listPage = [];
      if (this.contentLibraryById && this.contentLibraryById.length > 0) {
        this.uiNote = this.contentLibraryById[0].design_settings;
        // const arrayPage = [];
        this.dataCata = this.contentLibraryById[0].catalogue.filter(
          (item) => item.id == this.idCata
        );
        this.listCata = this.contentLibraryById[0].catalogue;

        this.idPageNext = Number(this.idCata) + 1;
        this.idPagePrev = Number(this.idCata) - 1;
        if (this.contentLibraryById[0].catalogue) {
          this.contentLibraryById[0].catalogue.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          );
        }
        this.contentLibraryById[0].catalogue.forEach((element) => {
          const objectPage = {
            idChapter: null,
            page: [],
            position: null,
          };
          if (element.page && element.page.length > 0) {
            element.page.forEach((item) => {
              objectPage.page.push(item.id);
            });
          } else {
            objectPage.page.push(element.id);
          }
          objectPage.position = element.position;
          objectPage.idChapter = element.catalogue_id;
          this.listPage.push(objectPage);
        });
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
    },

    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.isLoading = false;
        // this.getListTag(this.$route.params.shopId);
      }
    },

    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },

    listTag() {
      this.listTagOptions = this.listTag.map((item) => ({
        tag_name: item.tag_name,
        tag_id: item.id,
      }));
      if (this.selectedTag.length > 0) {
        this.listTagOptions.forEach((element) => {
          this.selectedTag.forEach((item) => {
            if (element.tag_name === item.tag_name) {
              this.$set(element, "checked", true);
            }
          });
        });
      }
    },

    noteById() {
      if (this.noteById) {
        this.detailNote = this.noteById;
        this.detail_title = this.detailNote.title_note;
        this.detail_content = this.detailNote.content_note;
        this.detail_video = this.detailNote.seconds_watch_video;
        this.detail_pdf = this.detailNote.page_pdf;
        this.selectedTag = this.noteById.tags;
        this.detail_page_title = this.noteById.page_title;
        if (this.selectedTag.length > 0) {
          this.listTagOptions.forEach((element) => {
            this.selectedTag.forEach((item) => {
              if (element.tag_name === item.tag_name) {
                this.$set(element, "checked", true);
              }
            });
          });
        }
      }
    },
    async listNote() {
      const { params } = this.$route;
      const dataNotWithPage = this.listNote.filter(
        (item) => item.page_id === this.idPageView
      );
      if (dataNotWithPage.length > 0) {
        this.lastPagePDF =
          dataNotWithPage[0].page_pdf === 0 ? 1 : dataNotWithPage[0].page_pdf;
        this.timeWatchingEmbed =
          dataNotWithPage[0].seconds_watch_video === 0
            ? 1
            : dataNotWithPage[0].seconds_watch_video;
      } else {
        this.lastPagePDF = 1;
        this.timeWatchingEmbed = 0;
      }
      // if (this.listNote.length > 0) this.loadDataNote = false;
      await this.getCatalogue({
        id: params.idPage,
        content_id: this.$route.params.idContent,
      });
      this.getDataWatching();
    },
    idPageView() {
      const dataNotWithPage = this.listNote.filter(
        (item) => item.page_id === this.idPageView
      );
      if (dataNotWithPage.length > 0) {
        this.lastPagePDF =
          dataNotWithPage[0].page_pdf === 0 ? 1 : dataNotWithPage[0].page_pdf;
      } else {
        this.lastPagePDF = 1;
      }
    },
    catalogueById() {
      // this.uiNote = this.catalogueById[0].design_settings;
      // this.catalogueById.map((item) => {
      //   item.page_content = JSON.parse(item.page_content);
      //   const blocksData = item.page_content.blocks;
      //   blocksData.map((itemBlock) => {
      //     if (itemBlock.type === "toggle") {
      //       const indexBlockToggle = blocksData.indexOf(itemBlock);
      //       console.log(indexBlockToggle);
      //       if (itemBlock.data.child.length >= itemBlock.data.items) return;
      //       for (let i = 1; i <= itemBlock.data.items; i++) {
      //         console.log(i);
      //         console.log(blocksData[indexBlockToggle + i]);
      //         itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
      //         const indexObj = blocksData.indexOf(
      //           blocksData[indexBlockToggle + i]
      //         );
      //         console.log(indexObj, "indexObj");
      //         if (itemBlock.data.status === "closed")
      //           blocksData[indexBlockToggle + i].showDisplay = true;
      //         blocksData[indexBlockToggle + i].idChild = itemBlock.id;
      //       }
      //     }
      //   });
      // });
      // if (this.catalogueById.length > 0) this.getDataWatching();
    },
    page() {
      this.getListChats(this.page);
    },
    listMessageByChatID() {
      if (this.listMessageByChatID && this.listMessageByChatID.length > 0) {
        this.idChat = this.listMessageByChatID[0].chat_id;
        this.isShowPopupRead = this.listMessageByChatID[0].is_read;
        // var element = document.getElementById("bodyChat");
        // element.scrollTo(0, element.scrollHeight);
      }
    },
    // pageById() {
    //   // eslint-disable-next-line no-debugger
    //   debugger;
    //   this.detailPage = this.pageById;
    //   this.page_Content = JSON.parse(this.pageById.page_content);
    // },
  },
  computed: {
    ...mapGetters([
      "contentLibraryById",
      "success",
      "error",
      "message",
      "noteById",
      "listTag",
      "catalogueById",
      "listChat",
      "listMessageByChatID",
      "userProfile",
      "isShowButtonChatOrNote",
      "clickOnPDF",
      "detailCheckSentence",
    ]),
  },
  mounted() {
    // eventBus.$on("currentTimeYoutu", (data) => {
    //   this.seconds_watch_video = data;
    // });
    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      if (modalId === "modal-create-note" || modalId === "modal-edit-note") {
        this.getTCurrentEmbed();
      }
    });
    document.addEventListener("click", async (e) => {
      const origin = e.target.closest("a");
      if (origin) {
        console.clear();
        let href = origin.href;
        if (href) {
          if (href.includes("/fixed-page/")) {
            let splitHref = href.split("/");
            let splitHrefRevert = this.objectFlip(splitHref);
            if (splitHrefRevert["fixed-page"]) {
              let id = splitHref[Number(splitHrefRevert["fixed-page"]) + 1];
              const request = {
                id: id,
                shop_id: this.shop_id,
              };
              e.preventDefault();
              e.stopPropagation();
              let resp = await this.$store.dispatch(
                "getFixedPageUser",
                request
              );
              if (resp.data.type == 8) {
                this.$store.commit("set", ["idModalFixed", resp.data.id]);
              } else {
                if (origin.target !== "_blank") {
                  window.location.href = href;
                } else {
                  window.open(href, "_blank");
                }
              }
            }
          }
        }
      }
    });
  },
  methods: {
    ...mapActions({
      getCatalogue: "getCatalogue",
      getListNote: "getListNote",
      getListNoteBypage: "getListNoteBypage",
      getContentLibraryById: "getContentLibraryById",
      createNote: "createNote",
      deleteNote: "deleteNote",
      getNoteByIdUser: "getNoteByIdUser",
      updateNoteById: "updateNoteById",
      getListTag: "getListTag",
      createNewTag: "createNewTag",
      getListChat: "getListChat",
      createChat: "createChat",
      getMessageByChatID: "getMessageByChatID",
      sendMessage: "sendMessage",
      deleteMessageByID: "deleteMessageByID",
      updateViewCatalogue: "updateViewCatalogue",
      readPopupChat: "readPopupChat",
      getPageById: "getPageById",
      getProfile: "getProfile",
      getChatByPageId: "getChatByPageId",
      checkSentence: "checkSentence",
    }),
    objectFlip(obj) {
      return Object.keys(obj).reduce((ret, key) => {
        ret[obj[key]] = key;
        return ret;
      }, {});
    },
    async getDetailPage() {
      const reqData = {
        content_id: this.$route.params.idContent,
        catalogue_id: this.$route.query.idChapter,
      };
      return await Api.userRequestServer
        .post(
          `/${Urls.PAGE}/${Urls.SHOW}/${Urls.USER}/${this.$route.params.idPage}?shop_id=${this.shop_id}`,
          reqData
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.detailPage = data.data;
            if (this.detailPage.url_redirect) {
              this.checkShow = false;
              window.location.href = this.detailPage.url_redirect;
              this.$store.commit("set", ["modalLoadingPage", false]);
            } else this.checkShow = true;
            if (this.detailPage.is_page404 == true) {
              this.checkShow = false;
              this.$router.push({
                name: this.$route.params.shopId ? "page404" : "page404 domain",
                params: {
                  shopId: this.shop_id,
                },
              });
              this.$store.commit("set", ["modalLoadingPage", false]);
            } else this.checkShow = true;
            this.titleCata = data.data.page_title;
            this.page_Content = data.data.page_content;
            if (typeof this.page_Content === "string") {
              this.page_Content = JSON.parse(this.page_Content);
            }
            // change param sentence
            if (Object.keys(this.detailCheckSentence).length > 0) {
              this.page_Content.blocks.forEach((item) => {
                if (item.data && item.data.text) {
                  let paramStart = item.data.text.indexOf("{%");
                  let paramEnd = item.data.text.indexOf("%}");
                  if (paramStart >= 0 && paramEnd >= 0) {
                    let param = item.data.text.slice(paramStart + 2, paramEnd);

                    // case text row has attribute
                    let paramStartCss = param.indexOf(">");
                    let paramEndCss = param.lastIndexOf("<");
                    if (paramStartCss >= 0 && paramEndCss >= 0) {
                      let paramCss = param.slice(
                        paramStartCss + 1,
                        paramEndCss
                      );
                      if (this.detailCheckSentence.hasOwnProperty(paramCss)) {
                        paramCss = param.replace(
                          ">" + paramCss + "<",
                          ">" + this.detailCheckSentence[paramCss] + "<"
                        );
                        item.data.text = item.data.text.replace(
                          "{%" + param + "%}",
                          paramCss
                        );
                      }
                    } else if (this.detailCheckSentence.hasOwnProperty(param)) {
                      item.data.text = item.data.text.replace(
                        "{%" + param + "%}",
                        this.detailCheckSentence[param]
                      );
                    }
                  }
                }
              });
            }
            this.page_Content.blocks.map((item) => {
              if (item.type === "table") {
                item.data.contentTbody = item.data.content.filter(
                  (item, index) => index > 0
                );
                return item;
              }
              if (item.type === "attachesPDF") {
                item.data.file["view"] = null;
                item.data.file["idComponent"] = item.id;
              }
              //
              var blocksData = this.page_Content.blocks;
              if (item.type === "toggle") {
                item.data.status = "closed";
                const indexBlockToggle = this.page_Content.blocks.indexOf(item);
                if (item.data.child.length >= item.data.items) return;
                for (let i = 1; i <= item.data.items; i++) {
                  item.data.child.push(blocksData[indexBlockToggle + i]);
                  if (item.data.status === "closed")
                    blocksData[indexBlockToggle + i].showDisplay = true;
                  blocksData[indexBlockToggle + i].idChild = item.id;
                }
              }
            });
            return this.checkShow;
          } else {
            this.$store.commit("set", ["message", data.message]);
            this.$store.commit("set", ["error", true]);
          }
        })
        .catch(() => {
          // console.log(error);
        });
    },
    async getListNoteByPages() {
      const dataRequest = {
        shop_id: parseInt(this.shop_id),
        content_id: parseInt(this.$route.params.idContent),
        catalogue_id: parseInt(this.idChapterView),
        page_id: parseInt(this.idPageView),
      };
      return await Api.userRequestServer
        .post(`/${Urls.PAGE}/${Urls.NOTE}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.listNoteData = data.data;
            this.listNote = data.data;
            this.noteByPage = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async CreateNewNote() {
      if (this.title === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.content == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        let shop_id = this.shop_id;
        const { params } = this.$route;
        let listTag = this.selectedTag.map((item) => item.tag_id);
        const formData = {
          shop_id: shop_id,
          content_id: parseInt(params.idContent),
          page_id: parseInt(this.idPageView),
          catalogue_id: parseInt(this.idChapterView),
          tag_id: listTag,
          title_note: this.title,
          content_note: this.content,
          page_pdf: parseInt(this.page_pdf),
          seconds_watch_video: this.seconds_watch_video,
          json_pdf: localStorage.getItem("currentPagePDF"),
          json_video:
            localStorage.getItem("currentTimeYoutu") != null
              ? localStorage.getItem("currentTimeYoutu")
              : localStorage.getItem("currentTimeVimeo"),
        };
        await this.createNote(formData);
        const dataRequest = {
          shop_id: parseInt(this.shop_id),
          content_id: parseInt(this.$route.params.idContent),
          catalogue_id: parseInt(this.idChapterView),
          page_id: parseInt(this.idPageView),
        };
        this.getListNoteByPages(dataRequest);
        this.title = "";
        this.content = "";
        // this.page_pdf = "";
        // this.seconds_watch_video = "";
        this.selectedTag = [];
        this.acceptPDF = false;
        this.acceptVideo = false;
        // const dataRequest = {
        //   shop_id: parseInt(this.$route.params.shopId),
        //   content_id: parseInt(this.$route.params.idContent),
        //   catalogue_id: parseInt(this.idChapterView),
        //   page_id: parseInt(this.idPageView),
        // };
        this.getListNoteBypage(dataRequest);
        // this.getListNote(this.idContent);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-note");
        });
        this.isLoading = false;
      }
    },
    moment,
    showConfirm(id) {
      this.tag = "";
      this.dataModal = id;
    },

    async removeNote(id) {
      const formData = {
        id: id,
        shop_id: this.shop_id,
      };
      await this.deleteNote(formData);
      this.listNoteData = null;
      const dataRequest = {
        shop_id: parseInt(this.shop_id),
        content_id: parseInt(this.$route.params.idContent),
        catalogue_id: parseInt(this.idChapterView),
        page_id: parseInt(this.idPageView),
      };
      await this.getListNoteByPages(dataRequest);
      // this.getListNoteBypage(dataRequest);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-delete-note");
      });
    },

    async editNote() {
      if (this.detail_title === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.detail_content == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        let shop_id = this.shop_id;
        let listTag = this.selectedTag.map((item) => item.tag_id);
        const formData = {
          shop_id: shop_id,
          id: this.detailNote.note_id,
          content_id: this.detailNote.content_id,
          page_id: this.detailNote.page_id,
          tag_id: listTag,
          title_note: this.detail_title,
          content_note: this.detail_content,
          page_pdf: this.detail_pdf,
          seconds_watch_video: this.detail_video,
          status: 0,
        };
        await this.updateNoteById(formData);
        const dataRequest = {
          shop_id: parseInt(this.shop_id),
          content_id: parseInt(this.$route.params.idContent),
          catalogue_id: parseInt(this.idChapterView),
          page_id: parseInt(this.idPageView),
        };
        this.getListNoteByPages(dataRequest);
        this.getListNoteBypage(dataRequest);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-edit-note");
        });
        this.isLoading = false;
      }
    },

    async createTag() {
      if (this.tag === "") {
        this.$toasted.error("入力してからEnterを押してください");
      } else {
        const formData = {
          user_id: localStorage.getItem("user_id"),
          shop_id: this.shop_id,
          tag_name: this.tag,
        };
        const data = await await this.$store.dispatch("createNewTag", formData);
        if (data.success === true) {
          await this.getListTag(this.shop_id);
          const datatag = this.listTagOptions && this.listTagOptions[0];
          this.$set(datatag, "checked", true);
          this.addTag(datatag);
          this.tag = "";
        }
      }
    },

    addTag(item) {
      if (this.selectedTag.length === 0) {
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
          checked: true,
        });
      } else {
        this.selectedTag.map((value) => {
          if (value.tag_id === item.tag_id) {
            this.isAdded++;
          }
        });
        if (this.isAdded === 0) {
          this.selectedTag.push({
            tag_name: item.tag_name,
            tag_id: item.tag_id,
            checked: true,
          });
          this.isAdded = 0;
        } else {
          this.isAdded = 0;
        }
      }
    },

    removeTag(event) {
      this.index = this.selectedTag.indexOf(event.target);
      this.selectedTag.splice(event, 1);
    },

    nextStep() {
      if (this.listCata.length > this.$route.idPage) {
        this.$router
          .push({
            name: "DetailPageLibrary",
            params: {
              idPage: this.$route.idPage++,
              idContent: this.$route.idContent,
            },
          })
          .catch(() => {});
      }
    },

    prevStep() {},
    handleScroll: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY > 0) {
        el.setAttribute(
          "style",
          "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95"
        );
        // eslint-disable-next-line prettier/prettier
        el.style.height = window.offsetHeight - 125 + window.scrollY + "px";
        let topOj = el.offseTop;
        topOj = 125 - window.scrollY + "px";
        el.style.top = parseInt(topOj) < 0 ? "0px" : topOj;
      } else {
        el.setAttribute(
          "style",
          "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95; height: calc(100vh - 120px)"
        );
      }

      return window.scrollY > limit;
    },
    handleScrollChapter: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY > 0) {
        var styleString =
          this.isCategoryTitle === 1 && this.isSelectScreen !== 2
            ? "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95; overflow-y: auto"
            : "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95;";
        el.setAttribute("style", styleString);
        // eslint-disable-next-line prettier/prettier
        el.style.height = window.offsetHeight - 125 + window.scrollY + "px";
        let topOj = el.offseTop;
        topOj = 125 - window.scrollY + "px";
        el.style.top = parseInt(topOj) < 0 ? "0px" : topOj;
      } else {
        var styleText =
          this.isCategoryTitle === 1 && this.isSelectScreen !== 2
            ? "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95; height: calc(100vh - 120px); overflow-y: auto"
            : "position: fixed; top: 120px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 95; height: calc(100vh - 120px)";
        el.setAttribute("style", styleText);
      }

      return window.scrollY > limit;
    },
    handleScrollTitle: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY < 125) {
        el.setAttribute("style", "padding: 30px 50px");
      } else if (window.scrollY > 125) {
        el.setAttribute("style", "padding: 30px 50px 30px 150px");
      }
      return window.scrollY > limit;
    },
    handleScrollNote: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (el) {
        if (window.scrollY > 0) {
          el.setAttribute(
            "style",
            "overflow: auto; height: calc(100vh - 100px); max-height: 100vh"
          );
        } else {
          el.setAttribute(
            "style",
            "overflow: auto; height: calc(100vh - 100px); max-height: 78vh"
          );
        }
      }
      return window.scrollY > limit;
    },
    async callStartGetCurrentEmbed() {
      // const idPageView = this.idPageView;
      // const dataPageView = this.catalogueById.filter(
      //   (item) => item.id === idPageView
      // );
      const dataEmbed = this.detailPage.page_content.blocks.filter(
        (item) => item.type === "embed"
      );

      let idLastDataEmbed;
      if (dataEmbed.length >= 1) {
        const lastDataEmbed = dataEmbed[dataEmbed.length - 1];
        idLastDataEmbed = lastDataEmbed.id;
      }

      const dataPDF = this.detailPage.page_content.blocks.filter(
        (item) => item.type === "attachesPDF"
      );
      let idLastDataPDF;
      if (dataPDF.length >= 1) {
        const lastDataPDF = dataPDF[dataPDF.length - 1];
        idLastDataPDF = lastDataPDF.id;
      }
      await eventBus.$emit("getCurrtentPagePDF", idLastDataPDF);
      await eventBus.$emit("getCurrtentTimeYoutu", idLastDataEmbed);
      await eventBus.$emit("getCurrtentTimeVimeo", idLastDataEmbed);
    },
    handleScrollMessage: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY > 0) {
        let height = 340 - window.scrollY;
        if (height < 215) height = 215;
        el.setAttribute(
          "style",
          "height: calc(100vh - " +
            height +
            "px); transition: ease-in-out;transition: all 0.05s;z-index: 99;background-color: #668ad8b8;overflow-y: auto; overscroll-behavior-y: contain;"
        );
        // eslint-disable-next-line prettier/prettier
        el.style.height = window.offsetHeight - 170 + window.scrollY + "px";
        let topOj = el.offseTop;
        topOj = 170 - window.scrollY + "px";
        el.style.top = parseInt(topOj) < 0 ? "0px" : topOj;
      } else {
        el.setAttribute(
          "style",
          "transition: ease-in-out;transition: all 0.05s;z-index: 99;background-color: #668ad8b8; height: calc(100vh - (170px + 170px));overflow-y: auto; overscroll-behavior-y: contain;"
        );
      }

      return window.scrollY > limit;
    },
    getTCurrentEmbed() {
      // const idPageView = this.idPageView;
      // const dataPageView = this.catalogueById.filter(
      //   (item) => item.id === idPageView
      // );
      const dataEmbed = this.detailPage.page_content.blocks.filter(
        (item) => item.type === "embed"
      );
      if (dataEmbed.length >= 1) {
        const lastDataEmbed = dataEmbed[dataEmbed.length - 1];
        const idLastDataEmbed = lastDataEmbed.id;
        if (lastDataEmbed.data.service === "vimeo") {
          if (localStorage.getItem("currentTimeVimeo") === null) {
            this.seconds_watch_video = 0;
          } else {
            const dataObjCurrentTimeVimeo = JSON.parse(
              localStorage.getItem("currentTimeVimeo")
            );
            const dataArrayInfoVideo = dataObjCurrentTimeVimeo.filter(
              (item) => item.id === idLastDataEmbed
            );
            this.seconds_watch_video =
              dataArrayInfoVideo.length > 0 ? dataArrayInfoVideo[0].time : 1;
          }
        }
        if (lastDataEmbed.data.service === "youtube") {
          if (localStorage.getItem("currentTimeYoutu") === null) {
            this.seconds_watch_video = 0;
          } else {
            const dataObjCurrentYoutubeVimeo = JSON.parse(
              localStorage.getItem("currentTimeYoutu")
            );
            const dataArrayInfoVideo = dataObjCurrentYoutubeVimeo.filter(
              (item) => item.id === idLastDataEmbed
            );
            this.seconds_watch_video =
              dataArrayInfoVideo.length > 0 ? dataArrayInfoVideo[0].time : 1;
          }
        }
      }
      const dataPDF = this.detailPage.page_content.blocks.filter(
        (item) => item.type === "attachesPDF"
      );
      if (dataPDF.length >= 1) {
        const lastDataPDF = dataPDF[dataPDF.length - 1];
        const idLastDataPDF = lastDataPDF.id;
        if (localStorage.getItem("currentPagePDF") === null) {
          this.page_pdf = 1;
        } else {
          const dataObjCurrentPagePDF = JSON.parse(
            localStorage.getItem("currentPagePDF")
          );
          const dataArrayInfoPDF = dataObjCurrentPagePDF.filter(
            (item) => item.id === idLastDataPDF
          );
          this.page_pdf =
            dataArrayInfoPDF.length > 0 ? dataArrayInfoPDF[0].page : 1;
        }
      }
    },
    selectedListNote(selected, evt) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.topMdNote =
          125 - window.scrollY > 0 ? 125 - window.scrollY + "px" : "0px";
        this.heightMdNote = window.offsetHeight - 125 + window.scrollY + "px";
        // this.notCheck = true;
        this.isShow = true;
        if (selected === 0) {
          this.isListNote = 1;
        } else {
          this.isListNote = 0;
          this.isSelectScreen = 1;
          this.isShowChat = 0;
          this.isShow = false;
        }
        if (this.isCategoryTitle === 1) {
          this.isCategoryTitle = 0;
        }
      }, 100);
    },
    selectedCategoryTitle(selected) {
      this.topMdNote =
        125 - window.scrollY > 0 ? 125 - window.scrollY + "px" : "0px";
      this.heightMdNote = window.offsetHeight - 125 + window.scrollY + "px";
      // this.notCheck = true;
      this.isShow = true;
      if (selected === 0) {
        this.isCategoryTitle = 1;
        this.isSelectScreen = 1;
        this.isShowChat = 0;
      } else {
        this.isCategoryTitle = 0;
        this.isShow = false;
      }
      if (this.isListNote === 1) {
        this.isListNote = 0;
      }
    },
    ClickContentSelected(index) {
      if (this.isSelect === index) {
        this.isSelect = null;
      } else {
        this.isSelect = index;
      }
    },
    ClickTagChecked(item) {
      if (item.checked === true) {
        this.$set(item, "checked", false);
        this.selectedTag = this.selectedTag.filter(
          (ele) => ele.tag_id !== item.tag_id
        );
      } else {
        this.$set(item, "checked", true);
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
        });
      }
    },
    setDataModal() {
      this.callStartGetCurrentEmbed();
      const shop_id = this.shop_id;
      this.getListTag(shop_id);
      this.selectedTag = [];
      this.tag = "";
      this.title = "";
      this.content = "";
      // this.$bvModal.show("modal-create-note");
      this.$root.$emit("bv::show::modal", "modal-create-note");
    },
    closeModal() {
      this.$bvModal.hide("modal-create-note");
      this.tag = "";
      this.title = "";
      this.content = "";
      this.selectedTag = [];
    },
    showModalChat() {
      this.valueChat = null;
      this.$bvModal.show("modal-create-chat");
    },
    closeModalChat() {
      this.$bvModal.hide("modal-create-chat");
    },
    closeModalEdit() {
      this.$bvModal.hide("modal-edit-note");
      this.tag = "";
      this.title = "";
      this.content = "";
      this.selectedTag = [];
    },
    showModalEdit(id) {
      this.tag = "";
      this.title = "";
      this.content = "";
      // this.$store.commit("set", ["IdContent", id]);
      this.selectedTag = [];
      const shop_id = this.shop_id;
      this.getListTag(shop_id);
      this.getNoteByIdUser(id);
      this.$bvModal.show("modal-edit-note");
    },
    async accordionPageContent(id) {
      // const { params } = this.$route;
      const accordionId = "accordionId_" + id;
      this.page_pdf = 0;
      this.seconds_watch_video = 0;
      this.idPageView = id;
      const dataRequest = {
        shop_id: parseInt(this.shop_id),
        content_id: parseInt(this.$route.params.idContent),
        catalogue_id: parseInt(this.idChapterView),
        page_id: parseInt(this.idPageView),
      };
      this.loadDataNote = true;
      localStorage.removeItem("currentTimeYoutu");
      localStorage.removeItem("currentTimeVimeo");
      localStorage.removeItem("currentPagePDF");
      this.$root.$emit("bv::toggle::collapse", accordionId);
      await this.getListNoteByPages(dataRequest);
      // await this.getCatalogue(params.idPage);
      // await this.getDataWatching();
    },
    getDataWatching() {
      // const dataNoteCurrentPage = this.listNote.filter(
      //   (item) => item.page_id === this.idPageView
      // )[0];
      // if (this.catalogueById.length < 1) {
      //   return;
      // }
      // // this.idPageView = this.catalogueById[0].id;
      // const dataPageView = this.catalogueById.filter(
      //   (item) => item.id === this.idPageView
      // );
      // const dataPDF = dataPageView[0].page_content.blocks.filter(
      //   (item) => item.type === "attaches"
      // );
      // const dataEmbed = dataPageView[0].page_content.blocks.filter(
      //   (item) => item.type === "embed"
      // );
      // if (dataNoteCurrentPage !== undefined) {
      //   const dataNotePDF = JSON.parse(dataNoteCurrentPage.json_pdf);
      //   const dataNoteEmbed = JSON.parse(dataNoteCurrentPage.json_video);
      //   if (dataNotePDF != null) {
      //     const dataObjLastPDF = dataNotePDF.filter(
      //       (item) => item.lasteId === true
      //     )[0];
      //     this.idLastPDF = dataObjLastPDF.id;
      //   } else {
      //     if (dataPDF.length > 0) {
      //       const lastDataPDF = dataPDF[dataPDF.length - 1];
      //       this.idLastPDF = lastDataPDF.id;
      //     }
      //   }
      //   if (dataNoteEmbed != null) {
      //     const dataObjLastEmbed = dataNoteEmbed.filter(
      //       (item) => item.lasteId === true
      //     )[0];
      //     this.idLastEmbed = dataObjLastEmbed.id;
      //   } else {
      //     if (dataEmbed.length > 0) {
      //       const lastDataEmbed = dataEmbed[dataEmbed.length - 1];
      //       this.idLastEmbed = lastDataEmbed.id;
      //     }
      //   }
      // }
      // if (
      //   this.idPageView === 1 &&
      //   this.idPageView != this.catalogueById[0].id
      // )
      // this.idPageView = this.catalogueById[0].id;
      // const dataPageView = this.catalogueById.filter(
      //   (item) => item.id === this.idPageView
      // );
      // const dataEmbed = dataPageView[0].page_content.blocks.filter(
      //   (item) => item.type === "embed"
      // );
      // const dataPDF = dataPageView[0].page_content.blocks.filter(
      //   (item) => item.type === "attaches"
      // );
      // if (dataEmbed.length > 0) {
      //   const lastDataEmbed = dataEmbed[dataEmbed.length - 1];
      //   this.idLastEmbed = lastDataEmbed.id;
      // }
      // if (dataPDF.length > 0) {
      //   const lastDataPDF = dataPDF[dataPDF.length - 1];
      //   this.idLastPDF = lastDataPDF.id;
      // }
    },
    async changeNoteOrChat(value) {
      this.isSelectScreen = value;
      //open chat
      if (value == 2) {
        this.$store.commit("set", ["listMessageByChatID", null]);
        this.isShowChat = 1;
        let el = document.getElementById("bodyChat");
        if (window.scrollY > 0) {
          let height = 340 - window.scrollY;
          if (height < 215) height = 215;
          this.heightMessage = "calc(100vh - (" + height + "px))";
        } else {
          this.heightMessage = "calc(100vh - (340px))";
        }
        const dataReq = {
          page_id: this.$route.params.idPage,
          content_id: this.$route.params.idContent,
          shop_id: this.shop_id,
        };
        if (this.$route.query.idChapter) {
          dataReq["catalogue_id"] = this.$route.query.idChapter;
        }
        await this.getChatByPageId(dataReq);
        this.gotoBottom();
      } else {
        this.isShowChat = 0;
        setInterval(function () {
          let elNote = document.getElementById("listNote");
          if (elNote) {
            if (window.scrollY > 0) {
              elNote.setAttribute(
                "style",
                "overflow: auto; height: calc(100vh - 100px); max-height: 100vh"
              );
            } else {
              elNote.setAttribute(
                "style",
                "overflow: auto; height: calc(100vh - 100px); max-height: 78vh"
              );
            }
          }
        }, 1000);
      }
    },
    async getListChats(page) {
      if (page === undefined) {
        page = this.page;
      }
      // const formData = {
      //   page: page,
      //   data: {
      //     shop_id: parseInt(this.shop_id),
      //     content_id: this.$route.params.idContent,
      //     limit: 10,
      //   },
      // };
      // await this.getListChat(formData);
      const dataReq = {
        page_id: this.$route.params.idPage,
        content_id: this.$route.params.idContent,
        shop_id: this.shop_id,
      };
      if (this.$route.query.idChapter) {
        dataReq["catalogue_id"] = this.$route.query.idChapter;
      }
      await this.getChatByPageId(dataReq);
      this.gotoBottom();
    },
    async CreateNewChat() {
      this.isLoading = true;
      const dataRequest = {
        shop_id: this.shop_id,
        content_id: this.$route.params.idContent,
        user_id: localStorage.getItem(Constants.USER_ID),
        message: this.valueChat,
        page_id: this.$route.params.idPage,
      };
      await this.createChat(dataRequest);
      this.isLoading = false;
      this.$bvModal.hide("modal-create-chat");
      await this.getListChats(1);
    },
    async getDetailMessageByChatID(item) {
      this.isShowChat = 1;
      const dataRequest = {
        idChat: item.id,
        shop_id: this.shop_id,
      };
      await this.getMessageByChatID(dataRequest);
      this.gotoBottom();
    },
    async ClickSendMessage() {
      if (!this.titleSendMessage || !this.titleSendMessage.trim()) {
        this.$toasted.error("文字が入力されていません");
        return;
      }
      if (this.isShowPopupRead === 0) {
        this.$bvModal.show("modal-alert-chat");
      } else {
        var dataRequest;
        var dataRepond;
        if (this.listMessageByChatID && this.listMessageByChatID.length == 0) {
          this.$bvModal.show("modal-alert-chat");
          // dataRequest = {
          //   shop_id: this.shop_id,
          //   content_id: this.$route.params.idContent,
          //   user_id: localStorage.getItem(Constants.USER_ID),
          //   message: this.titleSendMessage,
          //   page_id: this.$route.params.idPage,
          // };
          // if (this.$route.query.idChapter) {
          //   dataRequest["catalogue_id"] = this.$route.query.idChapter;
          // }
          // dataRepond = await this.createChat(dataRequest);
        } else {
          this.isLoading = true;
          dataRequest = {
            shop_id: this.shop_id,
            chat_id: this.idChat,
            message: this.titleSendMessage,
            user_id: localStorage.getItem(Constants.USER_ID),
          };
          if (this.$route.query.idChapter) {
            dataRequest["catalogue_id"] = this.$route.query.idChapter;
          }
          dataRepond = await this.sendMessage(dataRequest);
          this.isLoading = false;

          // if (dataRepond.success) {
          //   this.titleSendMessage = null;
          //   const dataReq = {
          //     id: this.idChat,
          //   };
          //   await this.getDetailMessageByChatID(dataReq);
          //   this.gotoBottom();
          // }
        }
        if (dataRepond && dataRepond.success) {
          this.logActivityChat(this.titleSendMessage, false);
          this.titleSendMessage = "";
          const dataReq = {
            page_id: this.$route.params.idPage,
            content_id: this.$route.params.idContent,
            shop_id: this.shop_id,
          };
          if (this.$route.query.idChapter) {
            dataReq["catalogue_id"] = this.$route.query.idChapter;
          }
          await this.getChatByPageId(dataReq);
          this.gotoBottom();
        }
      }
    },
    async applyAgree() {
      this.isLoading = true;
      this.checkNot();
      var dataRequest;
      var dataRepond;
      if (this.listMessageByChatID && this.listMessageByChatID.length == 0) {
        dataRequest = {
          shop_id: this.shop_id,
          content_id: this.$route.params.idContent,
          user_id: localStorage.getItem(Constants.USER_ID),
          message: this.titleSendMessage,
          page_id: this.$route.params.idPage,
          is_read: this.remember_me,
        };
        if (this.$route.query.idChapter) {
          dataRequest["catalogue_id"] = this.$route.query.idChapter;
        }
        dataRepond = await this.createChat(dataRequest);
        if (dataRepond && dataRepond.success) {
          this.logActivityChat(this.titleSendMessage, true);
        }
      } else {
        dataRequest = {
          shop_id: this.shop_id,
          chat_id: this.idChat,
          message: this.titleSendMessage,
          user_id: localStorage.getItem(Constants.USER_ID),
        };
        dataRepond = await this.sendMessage(dataRequest);
        if (dataRepond && dataRepond.success) {
          this.logActivityChat(this.titleSendMessage, false);
        }
      }
      this.isLoading = false;
      if (dataRepond.success) {
        this.$bvModal.hide("modal-alert-chat");
        this.titleSendMessage = "";
        // const dataRequest = {
        //   id: this.idChat,
        // };
        // await this.getDetailMessageByChatID(dataRequest);
        const dataReq = {
          page_id: this.$route.params.idPage,
          content_id: this.$route.params.idContent,
          shop_id: this.shop_id,
        };
        if (this.$route.query.idChapter) {
          dataReq["catalogue_id"] = this.$route.query.idChapter;
        }
        await this.getChatByPageId(dataReq);
        this.gotoBottom();
      }
    },
    BackNoteAndChat(value) {
      this.isShowChat = value;
    },
    async deletMessage(id) {
      this.checkDelete = id;
      const dataRequest = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataRepond = await this.deleteMessageByID(dataRequest);
      if (dataRepond.success) {
        this.titleSendMessage = "";
        // const dataRequest = {
        //   id: this.idChat,
        // };
        // await this.getDetailMessageByChatID(dataRequest);
        const dataReq = {
          page_id: this.$route.params.idPage,
          content_id: this.$route.params.idContent,
          shop_id: this.shop_id,
        };
        if (this.$route.query.idChapter) {
          dataReq["catalogue_id"] = this.$route.query.idChapter;
        }
        await this.getChatByPageId(dataReq);
        this.gotoBottom();
      }
    },
    readPopup() {
      if (this.listMessageByChatID.length > 0) {
        const dataRequest = {
          shop_id: this.shop_id,
          content_id: this.$route.params.idContent,
          is_read: 1,
        };
        if (this.idChat) {
          dataRequest["chat_id"] = this.idChat;
        }
        this.readPopupChat(dataRequest);
      }
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    PrevPage() {
      const positionId =
        this.$route.query.position != null
          ? parseInt(this.$route.query.position)
          : null;
      this.listPage.forEach((element, index) => {
        if (element.position === positionId) {
          const indexpage = element.page.indexOf(
            parseInt(this.$route.params.idPage)
          );
          if (indexpage === 0 && index === 0) {
            const IndexChapterMax = this.listPage.length - 1;
            const inddexPageMax =
              this.listPage[IndexChapterMax].page[
                this.listPage[IndexChapterMax].page.length - 1
              ];
            this.idPage = inddexPageMax;
            this.idContent = this.$route.params.idContent;
            this.idChapter = this.listPage[IndexChapterMax].idChapter;
            this.position = this.listPage[IndexChapterMax].position;
            return;
          } else if (indexpage === 0 && index !== 0) {
            const indexChapter = index - 1;
            const indexpage = this.listPage[indexChapter].page.length - 1;
            this.idPage = this.listPage[indexChapter].page[indexpage];
            this.idContent = this.$route.params.idContent;
            this.position = this.listPage[indexChapter].position;
            this.idChapter = this.listPage[indexChapter].idChapter;
            return;
          } else {
            const page = indexpage - 1;
            this.idPage = element.page[page];
            this.idContent = this.$route.params.idContent;
            this.idChapter = element.idChapter;
            this.position = element.position;
            return;
          }
        }
      });
      if (this.idPage) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "DetailPageLibrary"
            : "DetailPageLibrary domain",
          params: {
            idPage: this.idPage,
            idContent: this.idContent,
          },
          query: {
            idChapter: this.idChapter,
            position: this.position,
          },
        });
      }
    },
    NextPage() {
      let url_redirect = "";
      let positionId =
        this.$route.query.position != null
          ? parseInt(this.$route.query.position)
          : null;
      if (this.listPage && this.listPage.length > 0) {
        this.listPage.forEach((element, index) => {
          if (element.position === positionId) {
            const indexpage = element.page.indexOf(
              parseInt(this.$route.params.idPage)
            );
            if (
              indexpage === element.page.length - 1 &&
              index === this.listPage.length - 1
            ) {
              this.idPage = this.listPage[0].page[0];
              this.idContent = this.$route.params.idContent;
              this.idChapter = this.listPage[0].idChapter;
              this.position = this.listPage[0].position;
              return;
            } else if (
              indexpage === element.page.length - 1 &&
              index !== this.listPage.length - 1
            ) {
              const indexChapter = index + 1;
              this.idPage = this.listPage[indexChapter].page[0];
              this.idContent = this.$route.params.idContent;
              this.idChapter = this.listPage[indexChapter].idChapter;
              this.position = this.listPage[indexChapter].position;
              return;
            } else {
              const page = indexpage + 1;
              this.idPage = element.page[page];
              this.idContent = this.$route.params.idContent;
              this.position = element.position;
              this.idChapter = element.idChapter;
              return;
            }
          }
        });
      }
      if (this.idPage) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "DetailPageLibrary"
            : "DetailPageLibrary domain",
          params: {
            idPage: this.idPage,
            idContent: this.idContent,
          },
          query: {
            idChapter: this.idChapter,
            position: this.position,
          },
        });
      }
    },
    changepage(idpage, idChapter, position) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailPageLibrary"
          : "DetailPageLibrary domain",
        params: {
          idPage: idpage.id,
          idContent: this.$route.params.idContent,
        },
        query: {
          idChapter: idChapter,
          position: position,
        },
      });
    },
    gotoBottom() {
      var element = document.getElementById("bodyChat");
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        if (data.checkbox) {
          window.open(url, "_blank");
          return;
        }
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
    checkLimitLength(event) {
      let newValue = event.target.value;
      if (newValue.length > Constants.LIMIT_MESSAGE) {
        event.preventDefault();
        this.titleSendMessage = this.previousTitleSendMessage;
        this.$store.commit("set", ["message", "メッセージが長すぎます"]);
        this.$store.commit("set", ["error", true]);
      } else {
        this.titleSendMessage = newValue;
        this.previousTitleSendMessage = newValue;
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkNot() {
      this.notCheck = true;
    },
    checkCloseModal(e) {
      if (e.target.id !== undefined && !e.target.id.includes("modal-")) {
        if (
          this.isShow &&
          (this.isCategoryTitle == 1 || this.isListNote == 1)
        ) {
          if (!this.notCheck) {
            this.isShow = false;
            this.isCategoryTitle = 0;
            this.isListNote = 0;
          } else {
            this.notCheck = false;
          }
        }
      }
    },
    checkIndex(toggle) {
      var listToggle = this.page_Content.blocks.filter(
        (item) => item.idChild === toggle.idChild
      );
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },
    settingPlayerYoutube(id, video_Id) {
      const check = this;
      // eslint-disable-next-line no-undef
      new YT.Player(id, {
        // height: "390",
        // width: "640",
        videoId: video_Id,
        events: {
          onReady: check.onPlayerReady,
          onStateChange: check.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      let inforVideo = event.target.playerInfo;
      let videoID = inforVideo.videoData.video_id;
      let name = "time_yt_" + videoID;
      let videoTitle = inforVideo.videoData.title;
      let videoURL = inforVideo.videoUrl;
      if (event.data == this.statusYT["playing"]) {
        let time_start_view = this.updateTime(event.target.getCurrentTime());
        this.time_start_Yt = time_start_view;
        this.dynamicTime[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (event.data == this.statusYT["paused"]) {
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        this.logDataAction(
          1,
          videoTitle,
          videoURL,
          this.time_start_Yt,
          time_end_view,
          time_view
        );
      } else if (event.data == this.statusYT["ended"]) {
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          this.time_start_Yt,
          time_end_view,
          time_view
        );
      }
    },
    updateTime(time) {
      time = Math.round(time);
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = time - hours * 3600 - minutes * 60;

      hours = hours < 10 ? "0" + hours : hours;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var checkTime = hours + ":" + minutes + ":" + seconds;
      return checkTime;
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
    logDataAction(
      status,
      videoTitle,
      videoURL,
      time_start_view,
      time_end_view,
      time_view
    ) {
      const req = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: window.document.activeElement.name,
        video_url: videoURL,
        video_title: videoTitle,
        video_time_start_view: time_start_view,
      };
      if (status == this.statusClick["paused"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
      } else if (status == this.statusClick["end"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
        req.video_end_view = true;
      }
      req.id_page = this.$route.params.idPage;
      this.$store.dispatch("logActive", req);
    },
    logActivityChat(message, isNew) {
      let req = {
        shop_id: this.shop_id,
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        type_activity: "質問",
      };
      if (isNew) {
        req["question_title"] = message;
      } else {
        req["question_entry"] = message;
      }
      //  this.$store.dispatch("logActive", req);
    },
    //vimeo
    getVideoIDVimeo(src) {
      const regExp =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
      var match = src.match(regExp);
      const result = regExp.exec(src).slice(1);
      if (match) {
        let videoID = match[6];
        if (
          !src.includes("?") &&
          result[4] &&
          !result[4].includes("video") &&
          result[4].includes("/")
        ) {
          videoID = result[4].slice(0, -1);
        }
        return videoID;
      } else {
        console.log("not a vimeo url");
      }
    },

    async eventVimeoData(status, player, vimeoId) {
      let videoTitle = await player.getVideoTitle();
      let videoURL = await player.getVideoUrl();
      let name = "time_vimeo_" + vimeoId;
      let time = await player.getCurrentTime().then((time) => {
        return this.updateTime(time);
      });
      if (status == this.statusClick["start"]) {
        let time_start_view = time;
        this.time_start_Vm = time;
        this.dynamicTimeVimeo[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (status == this.statusClick["paused"]) {
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        let stateVideo = await player.getEnded();
        if (!stateVideo)
          this.logDataAction(
            1,
            videoTitle,
            videoURL,
            this.time_start_Vm,
            time_end_view,
            time_view
          );
      } else if (status == this.statusClick["end"]) {
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          this.time_start_Vm,
          time_end_view,
          time_view
        );
      }
    },

    async viewFilePDF(file) {
      this.$store.commit("set", ["clickOnPDF", true]);
      if (file.view === null) {
        const req = {
          shop_id: localStorage.getItem(Constants.SHOP_ID),
          url: window.document.activeElement.href
            ? window.document.activeElement.href
            : window.document.activeElement.baseURI,
          pdf_url: file.url,
        };
        const dataReturn = await this.$store.dispatch("countViewPDF", req);
        if (dataReturn.success) {
          file.view = dataReturn.data + 1;
        }
      } else {
        file.view = file.view + 1;
      }
      this.filePDFView = file;
      this.modalShow = !this.modalShow;
    },
    replaceMessage(message) {
      if (!message || !message.trim()) return "<div>" + "<div>";
      let messageList = message.split("\n");
      let html = "";
      messageList.forEach((elem) => {
        if (elem.includes("http://") || elem.includes("https://")) {
          let elemList = elem.split(" ");
          elemList.forEach((val) => {
            if (val.includes("http://") || val.includes("https://")) {
              html += '<a href="' + val + '" target="_blank">' + val + "</a>";
            } else {
              html += "<div>" + val + "<div>";
            }
          });
        } else {
          html += "<div>" + elem + "<div>";
        }
      });
      return html;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content-page {
  font-size: $fontSizeBase;
  font-family: $fontFamilyBase;
  line-height: $lineHeightBase;
}
.detail-content {
  min-height: 100vh;
  .dc-header {
    padding: 30px 50px;
    @media (max-width: 767px) {
      padding: 10px 0;
      font-size: 15px;
      border-bottom: none;
    }
    font-size: 18px;

    border-bottom: 1px solid #c4c4c4;
    a {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .dc-main {
    min-height: 100vh;
    width: 100%;
    &-list {
      overflow: hidden;
      width: calc(100vw - 100px);
      padding: 30px 50px;
      background-color: #fffde7;
      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0 !important;
      }
      img {
        max-width: 100% !important;
      }
      &-item {
        margin: 10px 0;
        .item-title {
          font-size: 20px;
          margin-bottom: 20px;
        }
        .item-comtent {
          width: 100%;
        }
      }
    }
  }
}
.dc-note {
  height: 100vh;
  width: 100px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  &-library {
    height: 15%;
  }
  &-catalog {
  }
  &-note {
  }
  &-step {
    justify-content: space-between;
    padding: 30px 0;
    width: 100%;
    &-item {
      height: 50%;
      width: 100px;
    }
  }
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .icon-note {
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
}
.list-tag {
  .item-tag {
    padding: 3px 10px;
    border: 2px solid orangered;
    cursor: pointer;
    background: orange;
    border-radius: 5px;
  }
}
.contents-list {
  &-item {
    padding: 20px;
    cursor: pointer;
    a {
      color: #000;
    }
    .icon-check {
      height: 25px;
      width: 25px;
      background: green;
      color: white;
      border-radius: 50%;
      margin-right: 10px;
      svg {
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .textName {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.list-note-item {
  padding: 20px;
  // border: 1px solid #000;
  // border-radius: 20px;
  .title-item {
    color: #383434;
    font-weight: 600;
  }
  .list-note-item-pdf {
    color: #383434;
    font-weight: 600;
  }
}
//.detail-content {
//  user-select: none; /* supported by Chrome and Opera */
//  -webkit-user-select: none; /* Safari */
//  -khtml-user-select: none; /* Konqueror HTML */
//  -moz-user-select: none; /* Firefox */
//  -ms-user-select: none; /* Internet Explorer/Edge */
//}

.nav-bar__left {
  transition: ease-in-out;
}
.sidebar-menu {
  width: 100%;
  overflow: hidden;
  display: block;
  top: auto;
  bottom: 0px;
  left: 0px;
  z-index: 1020;
  position: fixed;
  box-shadow: 0px -3px 6px #00000029;
  .sidebarList {
    background-color: #fff;
    height: 55px;
    z-index: 200;
    border-bottom: 0px;
    font-size: 10px;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
.height-text {
  height: 100vh;
  width: 30%;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.sidebarTtl {
  width: 100%;
  height: 81px;
  font-size: 20px;
  font-weight: normal;
  padding: 30px 0;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  line-height: 1;
  z-index: 11;
  text-align: center;
}
.isSelected {
  background: #f5f5f5;
}
.customA {
  background: #eee !important;
  border: 1px solid #eee !important;
  border-right: none !important;
  color: #1d1d1d !important;
}
.customA :hover {
  background: #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
  cursor: pointer;
}
.customA ::before {
  content: "";
  display: block;
  width: 18px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 18px 20px;
  position: absolute;
  top: -1px;
  left: 100%;
  transition: 0.2s ease-out;
}
.customSpan {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  display: block;
  height: 18px;
  line-height: 18px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  word-break: break-all;
  box-sizing: border-box;
  font-size: 11px;
  padding: 0 0 0 10px;
  cursor: pointer;
}
>>> .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
  height: 465px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
  padding: 20px !important;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
.customTag2 {
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #1d1d1d;
}
@media (max-width: 767px) {
  >>> .modalCustom > .modal-dialog {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }
  >>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
    width: 100%;
    overflow: auto;
    height: 400px;
  }
}
.customContentNoteMobile {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.hoverMouse:hover {
  border: 1px solid #ccc !important;
}
.hoverMouse:focus {
  border: 1px solid #ccc !important;
  box-shadow: none;
}
.title2 {
  color: #7f7f7f;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  display: flex;
  align-items: center;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
.title2::before,
.title2::after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1;
  color: #dfdadadb;
}
.title2:before {
  margin-right: 2rem;
}
.title2:after {
  margin-left: 2rem;
}
.addNote {
  right: 0;
  font-size: 14px;
  display: inline-block;
  color: #555;
  padding: 5px 30px 3px 0;
  background: url(../../assets/img/icon_note.png) 100% center no-repeat;
  background-size: 20px;
}
/* Style the tab */
.tab {
  overflow: hidden;
  background-color: white;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}
.customTitle {
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 30px 0 30px 20px;
  background-image: url(../../assets/img/icon_arrow01.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
}
.customChatAdmin {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.customChatUser {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.CustomInputChat {
  position: absolute;
  // bottom: 0px;
  left: 0;
  right: 0;
}
.icon-no-read {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.remember-form {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  align-items: center;
  label {
    font-size: 16px;
    color: #72777c;
    font-weight: bolder;
    margin: unset;
    cursor: pointer;
  }
  input {
    height: 20px;
    width: 20px;
  }
}
.isSelectedScreen {
  color: #2eb85c;
}
.customTitleChapter {
  width: 480px;
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
  display: block;
  line-height: 24px;
  text-align: left;
  height: auto;
}
.customTitlePage {
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
}
.line-catalogue-above {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 0;
}
.line-catalogue-below {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 50%;
}
.hoverTittlePage :hover {
  background-color: #ebedef;
}
.checkedPage {
  background-color: #d1d1d1;
}
.customTitlePage::before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 52px;
  top: 0;
}
.UINote {
  justify-content: center;
  margin-left: unset !important;
  // width: 620px !important;
  height: 100vh !important;
}
.UIHeaderNote {
  // width: 620px;
}
</style>

<style lang="scss">
body {
  background-color: white !important;
}
/*.default-layout .version-desktop {
  display: block !important;
}*/
.disable-div {
  pointer-events: none !important;
  user-select: none !important; /* supported by Chrome and Opera */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
}
</style>
